<template>
    <modal-lateral ref="verInstrucciones" titulo="Instrucciones de entrega">
        <div class="row m-3">
            <div class="col f-14">
                {{ detalle.instrucciones }}
            </div>
        </div>
        <div class="row m-3">
            <div class="col f-14">
                Pagará con: <b> {{ separadorNumero(detalle.billete) }}</b>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed:{
        ...mapGetters({
            detalle: 'reclamos/reclamos/detalle'
        }),

    },
    methods: {
        toggle(){
            this.$refs.verInstrucciones.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
